import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63')
];

export const server_loads = [0,8,10];

export const dictionary = {
		"/(default)": [~42,[9]],
		"/(default)/artworks": [48,[9]],
		"/(auth)/artworks/create": [16,[2]],
		"/(default)/artworks/[artwork_id]": [49,[9]],
		"/(auth)/characters/create": [17,[2]],
		"/(auth)/characters/profile": [18,[2]],
		"/(auth)/characters/settings/[id]": [19,[2]],
		"/(auth)/chats": [21,[2,3]],
		"/(auth)/chats/[character_id]": [22,[2,3,4]],
		"/(auth)/chats/[character_id]/[chat_id]": [23,[2,3,4]],
		"/(auth)/chat": [20,[2]],
		"/(console)/console": [~35,[8]],
		"/(console)/console/artworks": [36,[8]],
		"/(console)/console/banners": [37,[8]],
		"/(console)/console/characters": [38,[8]],
		"/(console)/console/features": [39,[8]],
		"/(console)/console/reports": [40,[8]],
		"/(console)/console/settings": [41,[8]],
		"/(default)/forgot-password": [50],
		"/(default)/i": [~51,[9,12]],
		"/(default)/i/[artwork_id]": [52,[9,12]],
		"/(default)/i/[artwork_id]/comments": [53,[9,12]],
		"/(auth)/notifications": [24,[2]],
		"/(auth)/pricing": [25,[2]],
		"/(default)/privacy": [54,[9]],
		"/(auth)/quest": [26,[2]],
		"/(default)/signin": [55],
		"/(default)/signup": [56],
		"/(auth)/subscription/summary": [27,[2]],
		"/(default)/support": [57,[9]],
		"/(default)/tos": [58,[9]],
		"/(auth)/user/profile": [28,[2,5]],
		"/(auth)/user/profile/badges": [29,[2,5]],
		"/(auth)/user/profile/c": [30,[2,5]],
		"/(auth)/user/profile/i": [31,[2,5,6]],
		"/(auth)/user/profile/i/[artwork_id]": [32,[2,5,6]],
		"/(auth)/user/profile/subscriptions": [33,[2,5]],
		"/(auth)/user/settings/[id]": [34,[2,7]],
		"/(default)/u": [59,[9,13]],
		"/(default)/u/[username]": [60,[9,13,14]],
		"/(default)/u/[username]/badges": [61,[9,13,14]],
		"/(default)/u/[username]/i": [62,[9,13,14,15]],
		"/(default)/u/[username]/i/[artwork_id]": [63,[9,13,14,15]],
		"/(default)/[username]": [43,[9,10]],
		"/(default)/[username]/badges": [45,[9,10]],
		"/(default)/[username]/f": [46,[9,10,11]],
		"/(default)/[username]/f/[artwork_id]": [47,[9,10,11]],
		"/(default)/[username]/[artwork_id]": [44,[9,10]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';