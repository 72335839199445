import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import { dev } from '$app/environment';
import { PUBLIC_ENVIRONMENT } from '$env/static/public';
import { page } from '$app/stores';
import { logger } from '$lib/logger';
import reports, { feedback } from '$lib/feedback';
import insight, { visit } from '$lib/insight';

let handler;

reports.init({
	dsn: 'https://d03cba55c724cc1d8b90ace2e6628d8c@o4505575525253120.ingest.sentry.io/4505948070150144',
	tracesSampleRate: 1.0,
	environment: PUBLIC_ENVIRONMENT ?? 'production',

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [new Replay(), feedback],
	ignoreErrors: [
		// Random plugins/extensions
		'top.GLOBALS',
		// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
		'originalCreateNotification',
		'canvas.contentDocument',
		'MyApp_RemoveAllHighlights',
		'http://tt.epicplay.com',
		"Can't find variable: ZiteReader",
		'jigsaw is not defined',
		'ComboSearch is not defined',
		'http://loading.retry.widdit.com/',
		'atomicFindClose',
		// Facebook borked
		'fb_xd_fragment',
		// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
		// reduce this. (thanks @acdha)
		// See http://stackoverflow.com/questions/4113268
		'bmi_SafeAddOnload',
		'EBCallBackMessageReceived',
		// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
		'conduitPage'
	],
	denyUrls: [
		// Facebook flakiness
		/graph\.facebook\.com/i,
		// Facebook blocked
		/connect\.facebook\.net\/en_US\/all\.js/i,
		// Woopra flakiness
		/eatdifferent\.com\.woopra-ns\.com/i,
		/static\.woopra\.com\/js\/woopra\.js/i,
		// Chrome extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^chrome-extension:\/\//i,
		// Other plugins
		/127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
		/webappstoolbarba\.texthelp\.com\//i,
		/metrics\.itunes\.apple\.com\.edgesuite\.net\//i
	]
});
insight.init({ persistence: 'localStorage' });

let activeUserId: string;
let activeURL: string;

page.subscribe(({ url, data }) => {
	if (!data) return;

	if (activeURL !== url.href) {
		activeURL = url.href;
		visit(url);
	}

	if (typeof data?.session?.userData === 'object') {
		const { userData } = data.session;
		if (userData.id === activeUserId) return;

		logger.verbose('[sentry:scope] Configuring scope.', userData);

		activeUserId = userData.id;

		reports.setUser({
			id: userData.id,
			email: userData.email,
			username: userData.username,
			display_name: userData.display_name
		});
		insight.identify(userData.id, {
			$distinct_id: userData.id,
			$email: userData.email,
			$username: userData.username,
			$name: userData.display_name || `${userData.first_name} ${userData.last_name}`,
			$subscription: userData.subscription_plan
		});
	} else {
		logger.verbose('[sentry:scope] Clearing scope.');
		reports.setUser(null);
		insight.reset();
	}
});

if (!dev && PUBLIC_ENVIRONMENT !== 'local') {
	handler = handleErrorWithSentry();
}

export const handleError = handler;
